import { DatePipe } from '@angular/common';
import { Injector, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ServiceWorkerModule } from '@angular/service-worker';

import { DEFAULT_COMPONENT_ICONS } from '@azure/communication-react';
import { registerIcons } from '@fluentui/react';
import { initializeIcons } from '@fluentui/react/lib/Icons';
import { AppComponent } from './app.component';
import { AppCoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';

import { MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions, MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS } from '@angular/material/legacy-tooltip';
import { ServiceLocator } from 'weavix-shared/services/service-locator';

export const matTooltipOptions: MatTooltipDefaultOptions = {
    showDelay: 0,
    hideDelay: 0,
    touchGestures: 'auto',
    position: 'below',
    touchendHideDelay: 0,
    disableTooltipInteractivity: true,
};

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        BrowserModule,
        AppCoreModule,
        SharedModule,
        ServiceWorkerModule.register('custom-service-worker.js', { enabled: true }),
    ],
    providers: [
        DatePipe,
        { provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: matTooltipOptions },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {
    constructor(injector: Injector) {
        ServiceLocator.injector = injector;
        initializeIcons();
        registerIcons({ icons: DEFAULT_COMPONENT_ICONS });
    }
}

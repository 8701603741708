import { bootstrap } from '../bootstrap';
bootstrap();

import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { environment } from 'environments/environment';
import { AppModule } from './app/app.module';
import { AnalyticsService } from 'weavix-shared/services/analytics.service';
import { initializeNewRelic } from 'weavix-shared/utils/initialize-new-relic';

if (environment.production) {
  enableProdMode();
}

initializeNewRelic();

platformBrowserDynamic().bootstrapModule(AppModule).then(() => {
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/custom-service-worker.js');
    }
}).catch(err => console.error(err));

AnalyticsService.start();
